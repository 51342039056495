<mat-card
  appearance="outlined"
  class="general-dialog"
>
  <mat-card-header>
    <mat-card-title class="general__dialog--main-header">
      <h4 class="main-header">{{ 'feedback-module.command' | translate }}</h4>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div
      [formGroup]="form"
      class="flex flex-col gap-1"
    >
      <app-custom-select
        [inputLabel]="'feedback-module.form.type' | translate"
        [options]="typeOptions$ | async"
        formControlName="type"
        optionLabel="description"
      />
      <div class="w-full flex flex-col !gap-1">
        <mat-form-field class="w-full">
          <mat-label>
            {{ 'feedback-module.form.notes' | translate }}
          </mat-label>
          <app-custom-textarea
            id="notes"
            [formControl]="form.controls.notes"
          />
        </mat-form-field>
        <app-form-error [control]="form.controls.notes" />
      </div>
      <div>
        <label
          for="image"
          class="from-label pb-1"
        >
          {{ 'feedback-module.form.attachment' | translate }}
        </label>
        <img
          class="w-full"
          id="image"
          [src]="fileLink"
          [attr.alt]="'feedback-module.form.attachment' | translate"
        />
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions class="feedback--actions">
    <div>
      <button
        mat-button
        (click)="onCloseClick()"
      >
        {{ 'shared.cancel' | translate }}
      </button>
      <button
        type="submit"
        mat-raised-button
        cdkFocusInitial
        color="primary"
        [disabled]="form.invalid"
        (click)="onConfirmClick()"
      >
        <mat-icon>check</mat-icon>
        {{ 'shared.submit' | translate }}
      </button>
    </div>
  </mat-card-actions>
</mat-card>
