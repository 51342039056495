import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  catchError,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { BaseComponent } from '@shared/components/base/base.component';
import { DocumentTypeEnum } from '@shared/attachments/enums/document-type.enum';
import { RequestTypeEnum } from '@shared/attachments/enums/request-type.enum';
import { FileUploadResponseModel } from '@shared/attachments/models/file-upload-response.model';
import { AttachmentService } from '@shared/attachments/services/attachment.service';
import { SnackBarService } from '@shared/snack-bar/services/snack-bar.service';
import { FeedbackService } from '../_data-access/services/feedback.service';
import { FeedbackFormDialogComponent } from '../feedback-form-dialog/feedback-form-dialog.component';
import { FeedbackConfigModel } from '../models/feedback-config.model';
import { HasPermissionDirective } from '@root/app/shared/directives/has-permission.directive';
import { AppAccessEnum } from '@root/app/shared/enums/app-access.enum';

@Component({
  selector: 'app-feedback-trigger',
  standalone: true,
  imports: [
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    HasPermissionDirective,
  ],
  templateUrl: './feedback-trigger.component.html',
  styleUrls: ['./feedback-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FeedbackTriggerComponent extends BaseComponent {
  // Services
  private document = inject(DOCUMENT);
  private attachmentService = inject(AttachmentService);
  private matDialog = inject(MatDialog);
  private renderer = inject(Renderer2);
  private translateService = inject(TranslateService);
  private feedbackService = inject(FeedbackService);
  private toastService = inject(SnackBarService);
  // Consts
  readonly attachmentDocumentType = DocumentTypeEnum.ScreenCapture;
  readonly attachmentRequestType = RequestTypeEnum.Feedback;
  readonly appAccessEnum = AppAccessEnum;
  // Fns
  html2Canvas!: (
    element: HTMLElement,
    options?: Partial<unknown> | undefined
  ) => Promise<HTMLCanvasElement>;

  constructor() {
    super();
    this.createLoadingStyle();
  }

  async triggerFeedback() {
    this._setLoadingMode();
    from(this.getMainBlob())
      .pipe(
        filter((res) => !!res),
        switchMap((res) => {
          if (!res) throw new Error();
          return this.getFileMediaModel(res.blob).pipe(
            map((file) => ({
              file,
              link: res?.link,
            }))
          );
        }),
        tap(() => this._removeLoadingMode()),
        mergeMap(({ file, link }) => {
          const dialogRef = this.matDialog.open<
            FeedbackFormDialogComponent,
            FeedbackConfigModel
          >(FeedbackFormDialogComponent, {
            width: '500px',
            data: {
              file,
              fileLink: link as string,
            },
          });
          return dialogRef.afterClosed();
        }),
        switchMap((res) => {
          return this.feedbackService.createFeedback(res);
        }),
        tap((res) => {
          if (!!res) {
            this.toastService.success();
          }
        }),
        catchError(() => {
          this._removeLoadingMode();
          return of(null);
        }),
        takeUntil(this.destroySubject)
      )
      .subscribe();
  }

  private getFileMediaModel(
    blob: Blob | null
  ): Observable<FileUploadResponseModel | null> {
    if (!blob) return of(null);
    return this.attachmentService.uploadFile({
      file: new File([blob], 'screenshot.png', {
        type: blob.type,
      }),
      requestType: this.attachmentRequestType,
      documentType: this.attachmentDocumentType,
      otherDocumentType: '',
    });
  }

  async getMainBlob() {
    const mainElement = this.document.querySelector('main');
    if (!mainElement) return null;
    const html2canvas =
      this.html2Canvas ?? (await import('html2canvas')).default ?? null;
    const canvas = await html2canvas(mainElement);
    const blob: Blob | null = await new Promise((resolve, reject) => {
      try {
        canvas.toBlob((cnv) => resolve(cnv));
      } catch (e) {
        reject(e);
      }
    });
    return {
      blob,
      link: canvas.toDataURL(),
    };
  }

  private _setLoadingMode() {
    this.renderer.addClass(this.document.body, 'feedback-loading');
  }
  private _removeLoadingMode() {
    this.renderer.removeClass(this.document.body, 'feedback-loading');
  }
  private createLoadingStyle() {
    const styleElement = this.renderer.createElement('style');
    this.renderer.setProperty(
      styleElement,
      'textContent',
      `
      :root {
        --feedback-text: '${this.translateService.instant(
          'feedback-module.form.loading'
        )}';
      }
    `
    );
    this.renderer.appendChild(document.head, styleElement);
  }
}
