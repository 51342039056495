<div
  *appHasPermission="{
    code: appAccessEnum.SystemSettings_Feedbacks_Add
  }"
>
  <button
    mat-stroked-button
    color="primary"
    (click)="triggerFeedback()"
  >
    <mat-icon class="feedback-icon">open_in_new</mat-icon>
    {{ 'feedback-module.command' | translate }}
  </button>
</div>
