import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { permissionsGuard } from '@core/guards/permissions.guard';
import { withBreadcrumbData } from '@shared/breadcrumbs/utils/breadcrumb-utils';
import { LAYOUT_ROUTES_PERMISSIONS } from '@root/app/layout-routes';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        data: {
          ...withBreadcrumbData({
            label: 'ASIDE.FEEDBACK_MANAGEMENT',
          }),
        },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./feedback-list/feedback-list.component').then(
                (c) => c.FeedbackListComponent
              ),
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./feedback-details/feedback-details.component').then(
                (c) => c.FeedbackDetailsComponent
              ),
            data: {
              ...withBreadcrumbData({
                label: 'feedback-module.details.label',
              }),
            },
          },
        ],
      },

      { path: '**', redirectTo: '' },
    ],
    canMatch: [permissionsGuard(LAYOUT_ROUTES_PERMISSIONS.feedback)],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeedbackRoutingModule {}

@NgModule({
  imports: [FeedbackRoutingModule],
  exports: [],
})
export class FeedbackModule {}
