import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FileUploadResponseModel } from '@shared/attachments/models/file-upload-response.model';
import { KeyDescription } from '@shared/model/key-description';
import { FeedbackDtoPresenterModel } from '../_data-access/models/feedback-presenter.model';
import { FeedbackService } from '../_data-access/services/feedback.service';
import { FeedbackConfigModel } from '../models/feedback-config.model';
import { CustomSelectComponent } from '@shared/forms/custom-select/custom-select.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomTextareaComponent } from '@shared/forms/custom-textarea/custom-textarea.component';
import { FormErrorComponent } from '@shared/forms/form-error/form-error.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';

type FeedbackForm = {
  [prop in keyof FeedbackDtoPresenterModel]: FormControl<
    FeedbackDtoPresenterModel[prop]
  >;
};

@Component({
  selector: 'app-feedback-form-dialog',
  standalone: true,
  imports: [
    MatCardModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    CustomSelectComponent,
    AsyncPipe,
    MatIconModule,
    CustomTextareaComponent,
    FormErrorComponent,
    MatFormField,
    MatLabel,
  ],
  templateUrl: './feedback-form-dialog.component.html',
  styleUrls: ['./feedback-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackFormDialogComponent implements OnInit {
  private dialogRef = inject(MatDialogRef);
  private dialogData: FeedbackConfigModel = inject(MAT_DIALOG_DATA);
  private feedbackService = inject(FeedbackService);
  private fb = inject(NonNullableFormBuilder);

  protected typeOptions$!: Observable<KeyDescription[]>;
  protected form!: FormGroup<FeedbackForm>;
  protected fileLink!: string;
  ngOnInit() {
    this._initTypeOptions();
    this._initForm();
    const config: FeedbackConfigModel = this.dialogData;
    if (!!config?.file) {
      this.form.patchValue({
        screenCapture: config.file,
      });
    }
    if (config?.fileLink) {
      this.fileLink = config.fileLink;
    }
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }
  onConfirmClick(): void {
    if (this.form.invalid) return;
    this.dialogRef.close(this.form.value);
  }

  private _initTypeOptions() {
    this.typeOptions$ = this.feedbackService.getFeedbackTypes();
  }

  private _initForm() {
    this.form = this.fb.group({
      notes: this.fb.control('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      type: this.fb.control('', [Validators.required]),
      screenCapture: this.fb.control({} as FileUploadResponseModel, [
        Validators.required,
      ]),
    });
  }
}
